cp-cus-reinvite {
  .mat-checkbox {
    .mat-checkbox-layout {
      align-items: center;
      .mat-checkbox-label {
        text-wrap: wrap !important;
      }
      .mat-checkbox-inner-container {
        margin: 4px 8px auto 0 !important;
      }
    }
  }
}
