.mat-mdc-header-cell {
  @apply text-xs font-bold;
}

.mat-mdc-cell,
.mat-mdc-header-cell {
  padding: 0 !important;

  &:first-child {
    padding-left: 16px !important;
  }

  &:last-child,
  &:nth-child(2) {
    padding-right: 16px !important;
  }
}

.mat-column-userTableActions {
  width: 20px !important;
}

//.mat-mdc-cell, .mat-mdc-header-cell {
//
//  &:first-child {
//  }
//
//  &:last-child, &:nth-child(2) {
//  }
//}

.dark {
  table.trackable {
    tbody tr:hover {
      background: rgba(255, 255, 255, 0.04);
    }
  }
}
table.trackable {
  tbody tr:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.04);
  }
}

.mat-row.highlighted {
  background: rgba(0, 0, 0, 0.12);
}

tr.expandable-detail-row {
  height: 0 !important;

  & > td {
    padding: 0 !important;
  }
}
tr.expanded-detail-row {
  padding: 8px !important;
}
