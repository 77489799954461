@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'themes/themes';
@import 'custom/typography';
//Import custom styles
@import 'custom/application';
@import 'custom/dialog';
@import 'custom/background';
@import 'custom/container';
@import 'custom/forms';
@import 'custom/list';
@import 'custom/text';
@import 'custom/icons';
@import 'custom/tables';
@import 'custom/select';
//Import custom components
@import 'components/card';
@import 'components/reinvite';

.mat-mdc-tooltip {
  font-size: 10px;
}

.mat-mdc-button {
  letter-spacing: normal !important;
  text-align: center;
  white-space: nowrap;
}

.mdc-button {
  width: fit-content;
}

.mat-mdc-cell {
  font-size: 14px !important;
}
